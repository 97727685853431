
import { z } from 'zod'

// Represents a zoned date time
export interface ZonedDateTime {
    // Local date time
    local: Date,
    // UTC date time
    utc: Date,
    // Time zone
    zone: string
}

export const ParseZonedDateTime= z.string().transform<ZonedDateTime>((value, ctx) => {
    // Format is 'yyyy-MM-ddTHH:mm:ss+00:00 Timezone/Name'
    const dateIndex = value.indexOf(" ");
    const offsetIndex = value.indexOf("+");
    if (dateIndex === -1 || offsetIndex === -1) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid ZonedDateTime format"
        });
        return z.NEVER;
    }

    let utc = value.substring(0, dateIndex);
    if (utc.indexOf(':', offsetIndex) === -1) {
        // Add colon to offset
        utc += ":00";
    }

    return {
        local: new Date(value.substring(0, offsetIndex)),
        utc: new Date(utc),
        zone: value.substring(dateIndex + 1)
    }
});